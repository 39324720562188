import React, { useState } from "react";
import axios from "axios";
import EditIcon from '@mui/icons-material/Edit';
import { Link } from 'react-router-dom';
import { IconButton } from "@mui/material";

const InventoryScanner = () => {
  const [barcode, setBarcode] = useState("");
  const [product, setProduct] = useState(null);
  const [newQuantity, setNewQuantity] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [updateMessage, setUpdateMessage] = useState("");
  const adminToken = localStorage.getItem("adminToken");

  const handleScan = async () => {
    if (!barcode) {
      setError("Please enter a barcode.");
      return;
    }

    setLoading(true);
    setError("");
    setProduct(null);

    try {
      const response = await axios.post(
        "/products/barcode",
        { barcode },
        {
          headers: {
            Authorization: adminToken ? `Bearer ${adminToken}` : "",
            "Content-Type": "application/json",
          },
        }
      );

      if (response.data) {
        setProduct(response.data.product);
      } else {
        setError("No product found for this barcode.");
      }
    } catch (err) {
      setError("An error occurred while fetching the product. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  const handleUpdateQuantity = async () => {
    if (!newQuantity || isNaN(newQuantity) || Number(newQuantity) < 0) {
      setUpdateMessage("Please enter a valid quantity.");
      return;
    }
  
    setLoading(true);
    setUpdateMessage("");
  
    try {
      const response = await axios.post(
        `/products/update-quantity`,
        { quantity: Number(newQuantity), barcode: product.barcode }, // Use barcode instead of ID
        {
          headers: {
            Authorization: adminToken ? `Bearer ${adminToken}` : "",
            "Content-Type": "application/json",
          },
        }
      );
  
      if (response.data.success) {
        setProduct((prev) => ({
          ...prev,
          quantity: Number(newQuantity), // Update the quantity in the state
        }));
        setUpdateMessage("Quantity updated successfully.");
      } else {
        setUpdateMessage("Failed to update quantity. Please try again.");
      }
    } catch (err) {
      setUpdateMessage("An error occurred while updating the quantity.");
    } finally {
      setLoading(false);
    }
  };
  

  return (
    <div style={{ padding: "20px", maxWidth: "600px", margin: "auto" }}>
      <h2>Inventory Scanner</h2>
      <div style={{ marginBottom: "20px" }}>
        <input
          type="text"
          placeholder="Scan or Enter Barcode"
          value={barcode}
          onChange={(e) => setBarcode(e.target.value)}
          style={{ width: "100%", padding: "10px", fontSize: "16px" }}
        />
        <button
          onClick={handleScan}
          style={{
            marginTop: "10px",
            padding: "10px 20px",
            fontSize: "16px",
            cursor: "pointer",
          }}
        >
          Scan
        </button>
      </div>

      {loading && <p>Loading...</p>}

      {error && <p style={{ color: "red" }}>{error}</p>}

      {product && (
        <div style={{ border: "1px solid #ccc", padding: "20px", borderRadius: "5px" }}>
            <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}> 
          <h3>Product Details</h3>
            <Link to={`/admin/editproduct/${product._id}`}>
                <IconButton>
                    <EditIcon />
                </IconButton>
            </Link>
            </div>
          <p><strong>Name:</strong> {product.name}</p>
          <p><strong>Quantity Available:</strong> {product.quantity}</p>
          {product.images && <img src={product.images[0].url} alt={product.name} style={{ width: "100px" }} />}

          <div style={{ marginTop: "20px" }}>
            <h4>Update Quantity</h4>
            <input
              type="number"
              placeholder="Enter new quantity"
              value={newQuantity}
              onChange={(e) => setNewQuantity(e.target.value)}
              style={{ width: "100%", padding: "10px", fontSize: "16px" }}
            />
            <button
              onClick={handleUpdateQuantity}
              style={{
                marginTop: "10px",
                padding: "10px 20px",
                fontSize: "16px",
                cursor: "pointer",
              }}
            >
              Update Quantity
            </button>
            {updateMessage && <p style={{ marginTop: "10px", color: "green" }}>{updateMessage}</p>}
          </div>
        </div>
      )}
    </div>
  );
};

export default InventoryScanner;
