import React from 'react';
import './Sidebar.css';
import add_product_icon from './Assets/Product_Cart.svg';
import list_product_icon from './Assets/Product_list_icon.svg';
import ListAltIcon from '@mui/icons-material/ListAlt';
import BarChartIcon from '@mui/icons-material/BarChart';
import HistoryIcon from '@mui/icons-material/History';
import LogoutIcon from '@mui/icons-material/Logout';
import ReceiptIcon from '@mui/icons-material/Receipt';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import StarIcon from '@mui/icons-material/Star';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import { Link, useNavigate } from 'react-router-dom';
import logo from './Assets/Logo.png';

const Sidebar = () => {
  const navigate = useNavigate();
  const adminEmail = localStorage.getItem('adminEmail');

  const handleLogout = () => {
    localStorage.removeItem('adminToken');
    localStorage.removeItem('adminEmail');
    localStorage.removeItem('admin_login_date');
    navigate('/admin/login');
  };

  const restrictedEmails = ['gulberg@herbimed.pk', 'dha@herbimed.pk'];

  return (
    <div className='sidebar'>
      <div className="sidebar-top">
        <Link to='/admin' style={{ textDecoration: 'none' }}>
          <img src={logo} alt="Logo" className="sidebar-logo" />
        </Link>
        <Link to='/admin/pos' style={{ textDecoration: 'none' }}>
          <div className="sidebar-item">
            <ReceiptIcon />
            <p>Point of Sale</p>
          </div>
        </Link>
        <Link to='/admin/inventory' style={{ textDecoration: 'none' }}>
          <div className="sidebar-item">
            <img src={add_product_icon} alt="Update Stock" />
            <p>Update Stock</p>
          </div>
        </Link>
        <Link to='/admin/addproduct' style={{ textDecoration: 'none' }}>
          <div className="sidebar-item">
            <img src={add_product_icon} alt="Add Product" />
            <p>Add Product</p>
          </div>
        </Link>
        <Link to='/admin/listproduct' style={{ textDecoration: 'none' }}>
          <div className="sidebar-item">
            <img src={list_product_icon} alt="Product List" />
            <p>Product List</p>
          </div>
        </Link>
        <Link to='/admin/orders' style={{ textDecoration: 'none' }}>
          <div className="sidebar-item">
            <ListAltIcon />
            <p>List Orders</p>
          </div>
        </Link>
        <Link to='/admin/users' style={{ textDecoration: 'none' }}>
          <div className="sidebar-item">
            <PeopleAltIcon />
            <p>All Users</p>
          </div>
        </Link>
        {!restrictedEmails.includes(adminEmail) && (
          <>
            <Link to='/admin/statistics' style={{ textDecoration: 'none' }}>
              <div className="sidebar-item">
                <BarChartIcon />
                <p>View Statistics</p>
              </div>
            </Link>
            <Link to='/admin/sales' style={{ textDecoration: 'none' }}>
              <div className="sidebar-item">
                <LocalOfferIcon />
                <p>View Offers</p>
              </div>
            </Link>
            <Link to='/admin/featured' style={{ textDecoration: 'none' }}>
              <div className="sidebar-item">
                <StarIcon />
                <p>Featured</p>
              </div>
            </Link>
            <Link to='/admin/audit-logs' style={{ textDecoration: 'none' }}>
              <div className="sidebar-item">
                <HistoryIcon />
                <p>Audit Logs</p>
              </div>
            </Link>
          </>
        )}
      </div>
      <div className="sidebar-bottom">
        <div className="sidebar-item" onClick={handleLogout}>
          <LogoutIcon />
          <p>Logout</p>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
