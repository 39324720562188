import React, { useState } from "react";

const FAQSection = ({ faqs }) => {
  const [openIndex, setOpenIndex] = useState(null);

  const toggleFAQ = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  return (
    <div style={styles.container}>
      <h1 style={styles.title}>Frequently Asked Questions</h1>
      <div style={styles.faqList}>
        {faqs.map((faq, index) => (
          <div
            key={index}
            style={{
              ...styles.faqItem,
              ...(openIndex === index ? styles.activeItem : {}),
            }}
          >
            <div
              style={styles.question}
              onClick={() => toggleFAQ(index)}
            >
              {faq.question}
              <span style={styles.toggleIcon}>
                {openIndex === index ? "-" : "+"}
              </span>
            </div>
            {openIndex === index && (
              <div style={styles.answer}>{faq.answer}</div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

const styles = {
  container: {
    width: "100%",
    padding: "50px 20px",
    // background: "linear-gradient(135deg, #007BFF, #00C6FF)",
    color: "black",
    fontFamily: "'Arial', sans-serif",
  },
  title: {
    textAlign: "center",
    marginBottom: "40px",
    fontSize: "32px",
    fontWeight: "bold",
    letterSpacing: "1px",
    textTransform: "uppercase",
  },
  faqList: {
    maxWidth: "800px",
    margin: "0 auto",
    display: "flex",
    flexDirection: "column",
    gap: "20px",
  },
  faqItem: {
    backgroundColor: "rgba(255, 255, 255, 0.1)",
    padding: "20px",
    borderRadius: "10px",
    border: "1px solid rgba(255, 255, 255, 0.3)",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
    transition: "all 0.3s ease",
    cursor: "pointer",
  },
  activeItem: {
    backgroundColor: "rgba(255, 255, 255, 0.2)",
    border: "1px solid #fff",
    transform: "scale(1.02)",
  },
  question: {
    fontSize: "20px",
    fontWeight: "600",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    color: "black",
  },
  toggleIcon: {
    fontSize: "24px",
    fontWeight: "bold",
  },
  answer: {
    marginTop: "10px",
    fontSize: "18px",
    fontWeight: "400",
    lineHeight: "1.6",
    color: "black",
  },
};

export default FAQSection;
