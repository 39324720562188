import React from 'react';
import { Route, Routes } from 'react-router-dom';
import './AdminHome.css';
import Sidebar from '../components/Sidebar';
import AddProduct from './AddProduct';
import ListProduct from './ListProduct';
import ListOrders from './ListOrders';
import OrderDetail from './OrderDetail';
import EditProductPage from './EditProductPage';
import AdminStatistics from './AdminStatistics';
import AuditLogs from './AuditLogs';
import POSPage from './POSPage';
import SalesOffersPage from './SalesOffersPage';
import OfferDetails from './OfferDetails';
import Featured from './Featured';
import ProtectedRoutes from '../components/ProtectedRoutes'; 
import EditFeatured from './EditFeatured';
import UsersPage from './UsersPage';
import InventoryScanner from './InventoryScanner';

const AdminHome = () => {
  return (
    <div className="admin">
      <Sidebar />
      <div className="content">
        <Routes>
          <Route path="addproduct" element={<ProtectedRoutes><AddProduct /></ProtectedRoutes>} />
          <Route path="editproduct/:id" element={<ProtectedRoutes><EditProductPage /></ProtectedRoutes>} />
          <Route path="listproduct" element={<ProtectedRoutes><ListProduct /></ProtectedRoutes>} />
          <Route path="orders" element={<ProtectedRoutes><ListOrders /></ProtectedRoutes>} />
          <Route path="orders/:id" element={<ProtectedRoutes><OrderDetail /></ProtectedRoutes>} />
          <Route path="statistics" element={<ProtectedRoutes><AdminStatistics /></ProtectedRoutes>} />
          <Route path="audit-logs" element={<ProtectedRoutes><AuditLogs /></ProtectedRoutes>} />
          <Route path="pos" element={<ProtectedRoutes><POSPage /></ProtectedRoutes>} />
          <Route path="sales" element={<ProtectedRoutes><SalesOffersPage /></ProtectedRoutes>} />
          <Route path="sales/:id" element={<ProtectedRoutes><OfferDetails /></ProtectedRoutes>} />
          <Route path="featured" element={<ProtectedRoutes><Featured /></ProtectedRoutes>} />
          <Route path="editfeatured/:id" element={<ProtectedRoutes><EditFeatured /></ProtectedRoutes>} />
          <Route path="users" element={<ProtectedRoutes><UsersPage /></ProtectedRoutes>} />
          <Route path="inventory" element={<ProtectedRoutes><InventoryScanner /></ProtectedRoutes>} />
        </Routes>
      </div>
    </div>
  );
};

export default AdminHome;
