import React, { useState, useEffect } from "react";
import {
  Box,
  Container,
  Grid,
  Typography,
  TextField,
  Button,
  Card,
  CardContent,
  IconButton,
  Checkbox,
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  Avatar,
  Snackbar,
  Alert,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Autocomplete,
  CircularProgress,
  Tabs,
  Tab,
} from "@mui/material";
import { AddCircleOutline, RemoveCircleOutline } from "@mui/icons-material";
import { Formik, Form, Field } from "formik";
import axios from "../axiosConfig";
import "./POSPage.css";
import PriceDisplay from "../components/PriceDisplay";

const POSPage = () => {
  const [products, setProducts] = useState([]);
  const [searchResults, setSearchResults] = useState([]);
  const [barResults, setBarResults] = useState([]);
  const [orderProducts, setOrderProducts] = useState([]);
  const [totalAmount, setTotalAmount] = useState(0);
  const [searchTerm, setSearchTerm] = useState("");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [finalAmount, setFinalAmount] = useState(0);
  const [customers, setCustomers] = useState([]);
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [customerSearch, setCustomerSearch] = useState("");
  const [loading, setLoading] = useState(false);
  const [activeTab, setActiveTab] = useState(0); // New: Active tab for toggling between barcode scanner and search
  const [barcode, setBarcode] = useState(""); // New: Barcode input
  const adminToken = localStorage.getItem("adminToken");

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const response = await axios.get("/products/search");
        console.log("Products:", response.data);
        setProducts(response.data);
      } catch (error) {
        console.error("Error fetching products:", error);
      }
    };

    fetchProducts();
  }, []);

  useEffect(() => {
    if (searchTerm) {
      const results = products.filter((product) =>
        product.name.toLowerCase().includes(searchTerm.toLowerCase())
      );
      setSearchResults(results);
    } else {
      setSearchResults([]);
    }
  }, [searchTerm, products]);

  useEffect(() => {
    let calculatedFinalAmount = totalAmount;
    setFinalAmount(calculatedFinalAmount);
  }, [totalAmount]);

  useEffect(() => {
    if (customerSearch) {
      const fetchCustomers = async () => {
        try {
          const response = await axios.get("/user/getallusers", {
            params: { search: customerSearch },
            headers: { Authorization: `Bearer ${localStorage.getItem("adminToken")}` },
          });
          setCustomers(response.data.users || []);
        } catch (error) {
          console.error("Error fetching customers:", error);
        }
      };

      fetchCustomers();
    }
  }, [customerSearch]);

  const handleAddProduct = async (product) => {
    try {
      const existingProduct = orderProducts.find((item) => item.product === product._id);
      const res = await axios.get(`/sales/discount/${product._id}`);
      const maxDiscount = res.data.maxDiscount;

      if (existingProduct) {
        setOrderProducts(
          orderProducts.map((item) =>
            item.product === product._id ? { ...item, count: item.count + 1 } : item
          )
        );
      } else {
        setOrderProducts([
          ...orderProducts,
          {
            product: product._id,
            name: product.name,
            price: Math.floor(product.price * (1 - maxDiscount / 100)),
            count: 1,
            image: product.images[0]?.url,
            packSize: product.packSize,
          },
        ]);
      }
      setTotalAmount(totalAmount + Math.floor(product.price * (1 - maxDiscount / 100)));
    } catch (error) {
      console.error("Error fetching discount:", error);
    }
  };

  const handleRemoveProduct = (productId) => {
    const product = orderProducts.find((item) => item.product === productId);
    if (product.count > 1) {
      setOrderProducts(
        orderProducts.map((item) =>
          item.product === productId ? { ...item, count: item.count - 1 } : item
        )
      );
      setTotalAmount(totalAmount - product.price);
    } else {
      setOrderProducts(orderProducts.filter((item) => item.product !== productId));
      setTotalAmount(totalAmount - product.price);
    }
  };

  const handleBarcodeScan = async () => {
    if (!barcode) {
      setSnackbarMessage("Please enter a valid barcode.");
      setSnackbarOpen(true);
      
      return;
    }
    try {
      const response = await axios.post(
        "/products/barcode",
        { barcode },
        {
          headers: {
            Authorization: adminToken ? `Bearer ${adminToken}` : "",
            "Content-Type": "application/json",
          },
        }
      );
      const product = response.data.product;

      if (product) {
        setBarResults(product);
        handleAddProduct(product);
        
        setSnackbarMessage("Product added successfully!");
      } else {
        setSnackbarMessage("Product not found.");
      }
    } catch (error) {
      console.error("Error fetching product by barcode:", error);
      setSnackbarMessage("Error fetching product by barcode.");
    } finally {
      setSnackbarOpen(true);
    }
  };

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleCustomerSearchChange = (event, value) => {
    setCustomerSearch(value);
  };

  const handleCustomerSelect = (event, value) => {
    setSelectedCustomer(value);
  };

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  return (
    <Container maxWidth="lg">
      <Typography variant="h4" gutterBottom>
        Point of Sale (POS)
      </Typography>
      <Grid container spacing={4}>
        <Grid item xs={12} md={6}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs value={activeTab} onChange={handleTabChange}>
              <Tab label="Barcode Scanner" />
              <Tab label="Search Products" />
            </Tabs>
          </Box>
          {activeTab === 0 && (
            <Box sx={{ marginTop: 2 }}>
              <TextField
                label="Scan Barcode"
                variant="outlined"
                fullWidth
                value={barcode}
                onChange={(e) => setBarcode(e.target.value)}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    handleBarcodeScan();
                  }
                }}
              />
              <Button
                variant="contained"
                color="primary"
                fullWidth
                sx={{ marginTop: 2 }}
                onClick={handleBarcodeScan}
              >
                Add Product by Barcode
              </Button>
              {barResults && barResults._id && (
              <List>
                <ListItem key={barResults._id}>
                  <ListItemAvatar>
                    <Avatar
                      src={barResults.images && barResults.images[0]?.url}
                      alt={barResults.name}
                    />
                  </ListItemAvatar>
                  <ListItemText
                    primary={`${barResults.name} (${barResults.packSize})`}
                    secondary={
                      <>
                        <strong>Price:</strong> {barResults.price} <br />
                        <strong>Inventory:</strong> {barResults.quantity}
                      </>
                    }
                  />
                </ListItem>
              </List>
            )}
            </Box>
          )}
          {activeTab === 1 && (
            <Box sx={{ marginTop: 2 }}>
              <TextField
                label="Search Products"
                variant="outlined"
                fullWidth
                value={searchTerm}
                onChange={handleSearchChange}
                margin="normal"
              />
              <List>
                {searchResults.map((product) => (
                  <ListItem key={product._id}>
                    <ListItemAvatar>
                      <Avatar
                        src={product.images && product.images[0]?.url}
                        alt={product.name}
                      />
                    </ListItemAvatar>
                    <ListItemText
                      primary={`${product.name} (${product.packSize})`}
                      secondary={
                        <>
                          <strong>Price:</strong> {product.price} <br />
                          <strong>Inventory:</strong> {product.quantity}
                        </>
                      }
                    />
                    <Checkbox
                      checked={orderProducts.some(
                        (item) => item.product === product._id
                      )}
                      onChange={() => handleAddProduct(product)}
                      color="primary"
                      disabled={product.quantity === 0}
                    />
                  </ListItem>
                ))}
              </List>
            </Box>
          )}
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography variant="h6" gutterBottom>
            Customer
          </Typography>
          <Autocomplete
            options={customers}
            getOptionLabel={(option) => option.name}
            onInputChange={handleCustomerSearchChange}
            onChange={handleCustomerSelect}
            renderInput={(params) => (
              <TextField 
                {...params} 
                label="Search Customer" 
                variant="outlined" 
                fullWidth 
                InputProps={{
                  ...params.InputProps,
                  style: { 
                    padding: '8px 12px',
                    backgroundColor: '#f5f5f5',
                    borderRadius: '4px',
                    boxShadow: '0 1px 3px rgba(0, 0, 0, 0.2)',
                    width: "200px"
                  },
                }}
                InputLabelProps={{
                  style: {
                    fontSize: '16px',
                    color: '#333',
                  },
                }}
              />
            )}
            renderOption={(props, option) => (
              <li {...props}>
                <Box>
                  <Typography variant="body1">{option.name}</Typography>
                  <Typography variant="body2">Tier: {option.tier}</Typography>
                  <Typography variant="body2">Points: {option.loyaltyPoints}</Typography>
                </Box>
              </li>
            )}
          />
          <Typography variant="h6" gutterBottom style={{ marginTop: '20px' }}>
            Order Details
          </Typography>
          <Formik
            initialValues={{
              discount: 0,
              discountType: 'percentage',
              address: '',
            }}
            onSubmit={async (values, { resetForm }) => {
              setLoading(true);
              let calculatedFinalAmount = totalAmount;
              let discountAmount = 0;

              // Apply tier-based discount if a customer is selected
              if (selectedCustomer) {
                if (selectedCustomer.tier === 'silver') {
                  discountAmount += calculatedFinalAmount * 0.1;
                } else if (selectedCustomer.tier === 'gold') {
                  discountAmount += calculatedFinalAmount * 0.2;
                }
              }

              // Apply additional discount based on user input
              if (values.discountType === 'percentage') {
                discountAmount += calculatedFinalAmount * (values.discount / 100);
              } else {
                discountAmount += values.discount;
              }

              calculatedFinalAmount -= discountAmount;

              const orderData = {
                products: orderProducts,
                transaction_id: null,
                amount: calculatedFinalAmount,
                address: values.address,
                type: 'Shop',
                status: 'Delivered',
                phoneNumber: '0000',
                email: '@herbimed.pk',
                city: 'Lahore',
                summary: {
                  subtotal: totalAmount,
                  discount: discountAmount,
                  deliveryCharges: 0,
                  total: calculatedFinalAmount,
                },
              };

              

              // Attach user_id if a customer is selected
              if (selectedCustomer) {
                orderData.user_id = selectedCustomer._id;
              }

              const adminToken = localStorage.getItem('adminToken');
              const headers = adminToken ? { Authorization: `Bearer ${adminToken}` } : '';

              try {
                const response = await axios.post('/orders/pos', orderData, { headers });
                console.log('Order placed successfully:', response.data);

                if (selectedCustomer) {
                  const loyaltyPointsEarned = Math.floor(calculatedFinalAmount / 100);
                  const updatedLoyaltyPoints = selectedCustomer.loyaltyPoints + loyaltyPointsEarned;

                  await axios.post('/user/updateloyalty', { userId: selectedCustomer._id, loyaltyPoints: updatedLoyaltyPoints }, { headers });
                }

                // Reset the form
                setOrderProducts([]);
                setTotalAmount(0);
                resetForm();
                setSnackbarMessage('Order placed successfully!');
                setSnackbarOpen(true);
              } catch (error) {
                console.error('Error placing order:', error);
                setSnackbarMessage('Failed to place order.');
                setSnackbarOpen(true);
              }
              setLoading(false);
            }}
          >
            {({ values, handleChange }) => (
              <Form>
                {orderProducts.map((item) => (
                  <Card key={item.product} sx={{ marginBottom: 2 }}>
                    <CardContent>
                      <Grid container spacing={2} alignItems="center">
                        <Grid item xs={8}>
                        <ListItemAvatar>
                          <Avatar
                            src={item.image}
                            alt={item.name}
                          />
                        </ListItemAvatar>
                          <Typography variant="body1">
                            {item.name} ({item.packSize}) x {item.count}
                          </Typography>
                          <Typography variant="body2">Total: Rs {item.price * item.count}</Typography>
                        </Grid>
                        <Grid item xs={4} textAlign="right">
                          <IconButton color="primary" onClick={() => handleAddProduct(products.find(p => p._id === item.product))}>
                            <AddCircleOutline />
                          </IconButton>
                          <IconButton color="secondary" onClick={() => handleRemoveProduct(item.product)}>
                            <RemoveCircleOutline />
                          </IconButton>
                        </Grid>
                      </Grid>
                    </CardContent>
                  </Card>
                ))}
                <Typography variant="h6">
                  Total Amount: 
                  {values.discount > 0 ? (
                    <span>
                      <span style={{ textDecoration: 'line-through', marginRight: '8px' }}>Rs {totalAmount}</span>
                      <span style={{ color: 'red' }}>Rs {values.discountType === 'percentage' ? totalAmount - totalAmount * (values.discount / 100) : totalAmount - values.discount}</span>
                    </span>
                  ) : (
                    <span>Rs {totalAmount}</span>
                  )}
                </Typography>
                <FormControl component="fieldset" sx={{ marginTop: 2 }}>
                  <Typography variant="h6">Discount</Typography>
                  <RadioGroup
                    row
                    name="discountType"
                    value={values.discountType}
                    onChange={handleChange}
                  >
                    <FormControlLabel value="percentage" control={<Radio />} label="Percentage" />
                    <FormControlLabel value="value" control={<Radio />} label="Value" />
                  </RadioGroup>
                  <Field
                    as={TextField}
                    label={`Discount (${values.discountType === 'percentage' ? '%' : 'Rs'})`}
                    variant="outlined"
                    fullWidth
                    name="discount"
                    value={values.discount}
                    onChange={handleChange}
                    margin="normal"
                  />
                </FormControl>
                <FormControl component="fieldset" sx={{ marginTop: 2 }}>
                  <Typography variant="h6">Select Shop Address</Typography>
                  <RadioGroup
                    name="address"
                    value={values.address}
                    onChange={handleChange}
                  >
                    <FormControlLabel value="HerbiMed, Big City Plaza Liberty Chowk Gulberg-3 Lahore" control={<Radio />} label="Shop #1 : HerbiMed, Big City Plaza Liberty Chowk Gulberg-3 Lahore" />
                    <FormControlLabel value="HerbiMed, Plaza G2 Phase-1 DHA Lahore" control={<Radio />} label="Shop #2 : Plaza G2 Phase-1 DHA Lahore" />
                  </RadioGroup>
                </FormControl>
                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  fullWidth
                  sx={{ marginTop: 2 }}
                  disabled={loading}
                  startIcon={loading ? <CircularProgress size={20} /> : null}
                >
                  Place Order
                </Button>
              </Form>
            )}
          </Formik>
        </Grid>
      </Grid>
      <Snackbar open={snackbarOpen} autoHideDuration={3000} onClose={handleCloseSnackbar}>
        <Alert onClose={handleCloseSnackbar} severity={snackbarMessage.includes('successfully') ? 'success' : 'error'} sx={{ width: '100%' }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default POSPage;
