import React, { useState, useEffect } from 'react';
import { Box, Typography, Snackbar, Alert, CircularProgress } from '@mui/material';
import { useParams } from 'react-router-dom';
import axios from '../axiosConfig';
import BreadcrumbsComponent from '../components/Breadcrumbs';
import ProductDisplay from '../components/ProductDisplay';
import DescriptionBox from '../components/DescriptionBox';
import RelatedProducts from '../components/RelatedProducts';
import { Helmet } from 'react-helmet-async';
import './ProductPage.css';
import SEO from '../components/SEO';
import FAQSection from '../components/FAQSection';


const ProductPage = () => {
  const { name } = useParams();
  const [product, setProduct] = useState(null);
  const [relatedProducts, setRelatedProducts] = useState([]);
  const [mainImage, setMainImage] = useState('');
  const [selectedThumbnail, setSelectedThumbnail] = useState(0);
  const [tabIndex, setTabIndex] = useState(0);
  const [loading, setLoading] = useState(true);
  const [cart, setCart] = useState([]);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');


  const faqs = [
    {
      "question": "What is HerbiMed?",
      "answer": "HerbiMed is a trusted distributor of high-quality dietary supplements in Pakistan, sourced exclusively from reputed suppliers in the United States."
    },
    {
      "question": "Do you manufacture your supplements?",
      "answer": "No, HerbiMed does not manufacture supplements. We import them from trusted, well-known brands in the US to ensure the highest standards of quality and effectiveness."
    },
    {
      "question": "Are the supplements you sell authentic?",
      "answer": "Yes, all our supplements are 100% authentic, sourced directly from leading and certified suppliers in the US."
    },
    {
      "question": "What are your shipping charges?",
      "answer": "We offer a flat delivery rate of 200 PKR across Pakistan. Additionally, if your order exceeds 15,000 PKR, shipping is absolutely free."
    },
    {
      "question": "How can I benefit from discounts or loyalty programs?",
      "answer": "By signing up, you can earn loyalty points and enjoy discounts of up to 20% on select products."
    },
    {
      "question": "How do you ensure the quality of your supplements?",
      "answer": "All our products are sourced from suppliers that adhere to FDA (Food and Drug Administration) and GMP (Good Manufacturing Practices) guidelines in the US."
    },
    {
      "question": "Are your supplements suitable for everyone?",
      "answer": "Our supplements cater to a variety of health needs and are suitable for different age groups. However, consult your healthcare provider before starting any supplement, especially if you have specific medical conditions."
    },
    {
      "question": "How can I track my order?",
      "answer": "Once your order is shipped, you’ll receive a tracking number via email to monitor its status."
    },
    {
      "question": "How do I contact HerbiMed for questions or support?",
      "answer": "You can reach us through our customer service hotline, email, or social media channels. Visit our 'Contact Us' page for more information."
    }
  ];

  useEffect(() => {
    axios.get(`/products/name/${name}`)
      .then(response => {
        setProduct(response.data.product);
        setRelatedProducts(response.data.relatedProducts);
        setMainImage(response.data.product.images[0]?.url || '');
        setLoading(false);

        // Send view_item event to Google Analytics using gtag
        window.gtag('event', 'view_item', {
          currency: "PKR", 
          value: response.data.product.price,
          items: [
            {
              item_id: response.data.product._id,
              item_name: response.data.product.name,
              price: response.data.product.price,
              quantity: 1,
              category: response.data.product.category,
            }
          ]
        });
      })
      .catch(error => {
        console.error('Error fetching product:', error);
        setLoading(false);
      });

    // Load cart from local storage
    const storedCart = localStorage.getItem('cart');
    if (storedCart) {
      setCart(JSON.parse(storedCart));
    }
  }, [name]);

  const handleThumbnailClick = (src, index) => {
    setMainImage(src);
    setSelectedThumbnail(index);
  };

  const handleTabChange = (event, newValue) => {
    setTabIndex(newValue);
  };

  const handleSwipeChange = (index) => {
    setTabIndex(index);
  };

  const fetchDiscount = async (productId) => {
    try {
      const response = await axios.get(`/sales/discount/${productId}`);
      return response.data.maxDiscount;
    } catch (error) {
      console.error('Error fetching discount:', error);
      return 0;
    }
  };

  const handleAddToCart = async () => {
    if (product.quantity < 1) {
      setSnackbarMessage('Product is out of stock.');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
      return;
    }

    const discount = await fetchDiscount(product._id);
    const storedCart = localStorage.getItem('cart');
    let cart = storedCart ? JSON.parse(storedCart) : [];

    // Send add_to_cart event to Google Analytics using gtag
    window.gtag('event', 'add_to_cart', {
      debug_mode: true,
      currency: "PKR", 
      value: product.price,
      items: [
        {
          item_id: product._id,
          item_name: product.name,
          price: product.price,
          quantity: 1,
          category: product.category,
        }
      ]
    });

    const existingProductIndex = cart.findIndex(item => item.id === product._id);

    if (existingProductIndex !== -1) {
      cart[existingProductIndex].amount += 1;
    } else {
      // If product doesn't exist in cart, add it with amount 1
      cart.push({
        name: product.name,
        amount: 1,
        id: product._id,
        discount,
        price: product.price,
        image: product.images[0]?.url || '',
      });
    }

    setCart(cart);
    localStorage.setItem('cart', JSON.stringify(cart));

    setSnackbarMessage('Product added to cart.');
    setSnackbarSeverity('success');
    setSnackbarOpen(true);
  };

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  if (loading) {
    return <CircularProgress />;
  }

  if (!product) {
    return <Typography>Product not found.</Typography>;
  }
  console.log('metaHTML:', product.metaHTML);
  console.log(typeof product.metaHTML); // Should log "string"
  // Function to parse metaHTML string into individual tags
  const parseMetaHTML = (metaHTML) => {
    return metaHTML
      .split(/(?=<meta)/) // Split into individual meta tags
      .map((metaTag, index) => (
        <div
          key={index}
          dangerouslySetInnerHTML={{ __html: metaTag.trim() }}
        />
      ));
  };
  return (
    <Box className="product-page">
      
      {product.seo && (
        <SEO
          title={product.seo.title}
          description={product.seo.description}
          keywords={product.seo.keywords}
          ogTitle={product.seo.ogTitle}
          ogDescription={product.seo.ogDescription}
          ogImage={product.seo.ogImage}
        />
      )}

      <BreadcrumbsComponent category={product.category} productName={product.name} />
      <ProductDisplay
        product={product}
        mainImage={mainImage}
        handleThumbnailClick={handleThumbnailClick}
        selectedThumbnail={selectedThumbnail}
        handleAddToCart={handleAddToCart}
      />
      <DescriptionBox
        description={product.description}
        ingredients={product.ingredients}
        directions={product.directions}
        warning={product.warning}
        tabIndex={tabIndex}
        handleTabChange={handleTabChange}
        handleSwipeChange={handleSwipeChange}
      />
      {relatedProducts && relatedProducts.length > 0 ? <RelatedProducts relatedProducts={relatedProducts} /> : null}

      <FAQSection faqs={faqs} />

      <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={handleCloseSnackbar}>
        <Alert onClose={handleCloseSnackbar} severity={snackbarSeverity} sx={{ width: '100%' }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default ProductPage;
