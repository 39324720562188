import React from 'react';
import { Box, Container, Grid, Paper, Typography, IconButton } from '@mui/material';
import { Phone, Email, LocationOn } from '@mui/icons-material';
import './ContactUs.css';

const ContactUs = () => {
  const locations = [
    {
      name: 'Shop #1',
      address: 'Big City Plaza Liberty Chowk Gulberg-3 Lahore',
      phone: '042-35774125',
      mapLink: 'https://www.google.com/maps/place/Herbi+Med/@31.5106875,74.3014537,14z/data=!4m10!1m2!2m1!1sherbimed+h+block!3m6!1s0x3919046740000001:0xda886d0724364bed!8m2!3d31.5106875!4d74.3395625!15sChBoZXJiaW1lZCBoIGJsb2NrWhIiEGhlcmJpbWVkIGggYmxvY2uSARZoZWFsdGhfYW5kX2JlYXV0eV9zaG9w4AEA!16s%2Fg%2F11nn18154p?entry=ttu',
      imageUrl: 'https://static-maps.yandex.ru/1.x/?lang=en_US&ll=74.3395625,31.5106875&z=15&size=600,300&l=map&pt=74.3395625,31.5106875,pm2rdm'
    },
    {
      name: 'Shop #2',
      address: 'Plaza G2 Phase-1 DHA Lahore',
      phone: '042-35708001',
      mapLink: 'https://www.google.com/maps/place/Herbi+Med+Pk/@31.4825964,74.3940751,15z/data=!4m6!3m5!1s0x391905e0f7b609cf:0x5011cc95a9b5236c!8m2!3d31.4825964!4d74.3940751!16s%2Fg%2F11y75y65wf?hl=en&entry=ttu',
      imageUrl: 'https://static-maps.yandex.ru/1.x/?lang=en_US&ll=74.39431762695312,31.48241424560547&z=15&size=600,300&l=map&pt=74.39431762695312,31.48241424560547,pm2rdm'
    }
  ];

  return (
    <Box className="contact-us-container">
      <Container maxWidth="lg">
        <Typography variant="h3" component="h1" gutterBottom className="title">
          Get In Touch
        </Typography>
        <Typography variant="body1" className="subtitle" gutterBottom>
          We would love to hear from you! Reach out to us through any of the following ways.
        </Typography>

        <Grid container spacing={4} justifyContent="center" className="contact-details">
          <Grid item xs={12} md={6}>
            <Paper className="contact-paper" elevation={3}>
              <IconButton className="contact-icon">
                <Phone fontSize="large" />
              </IconButton>
              <Typography variant="h5" className="contact-title">Phone</Typography>
              <Typography variant="body1">+92 311 1380608</Typography>
              <Typography variant="body1">+92 333 4296207</Typography>
              
            </Paper>
          </Grid>
          <Grid item xs={12} md={6}>
            <Paper className="contact-paper" elevation={3}>
              <IconButton className="contact-icon">
                <Email fontSize="large" />
              </IconButton>
              <Typography variant="h5" className="contact-title">Email</Typography>
              <Typography variant="body1">info@herbimed.pk</Typography>
              <Typography variant="body1"><strong>OR</strong></Typography>
              <Typography variant="body1">herbimedtvs@gmail.com</Typography>
            </Paper>
          </Grid>
          {locations.map((location, index) => (
            <Grid item xs={12} md={6} key={index}>
              
              <Paper className="contact-paper" elevation={3}>
                <IconButton className="contact-icon">
                  <LocationOn fontSize="large" />
                </IconButton>
                <Typography variant="h5" className="contact-title">{location.name}</Typography>
                <Typography variant="body1">
                  <strong>{location.name}:</strong> {location.address}
                </Typography>
                <Typography variant="body1"><strong>Phone:</strong> {location.phone}</Typography>
                <a href={location.mapLink} target="_blank" rel="noopener noreferrer" style={{textDecoration:"none"}}>
                  <img src={location.imageUrl} alt={`Map to ${location.name}`} className="map-image" />
                </a>
              </Paper>
              
            </Grid>
          ))}
        </Grid>
      </Container>
    </Box>
  );
};

export default ContactUs;
