import React from 'react';
import { Grid, Typography, Box, TextField, InputAdornment, IconButton } from '@mui/material';
import { Facebook, Instagram, Send } from '@mui/icons-material';
import { Formik, Form, Field } from 'formik';
import {Link} from 'react-router-dom';
import './Footer.css';
import Logo from '../images/Logo.png';

const Footer = () => {
    return (
        <Box className="footer">
            <Grid container justifyContent="space-between" alignItems="center">
                <Grid item xs={12} sm={3}>
                    <Box className="logo-section">
                        <img src={Logo} alt="Logo" className="logo-image" />
                        <Typography variant="body2" className="footer-text">©2024 Herbi Med</Typography>
                        <Typography variant="body2" className="footer-text">All rights reserved</Typography>
                        <Box className="social-icons">
                            <IconButton href="https://www.tiktok.com/@herbimed" target="_blank" className="social-icon">
                            <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="24" height="24" viewBox="0 0 24 24"> <path d="M 6 3 C 4.3550302 3 3 4.3550302 3 6 L 3 18 C 3 19.64497 4.3550302 21 6 21 L 18 21 C 19.64497 21 21 19.64497 21 18 L 21 6 C 21 4.3550302 19.64497 3 18 3 L 6 3 z M 12 7 L 14 7 C 14 8.005 15.471 9 16 9 L 16 11 C 15.395 11 14.668 10.734156 14 10.285156 L 14 14 C 14 15.654 12.654 17 11 17 C 9.346 17 8 15.654 8 14 C 8 12.346 9.346 11 11 11 L 11 13 C 10.448 13 10 13.449 10 14 C 10 14.551 10.448 15 11 15 C 11.552 15 12 14.551 12 14 L 12 7 z"></path> </svg>
                            </IconButton>
                            <IconButton href="https://www.facebook.com/p/Herbi-Med-The-Vitamin-Shop-100063831862568/" target="_blank" className="social-icon">
                                <Facebook style={{ color: 'black' }}/>
                            </IconButton>
                            <IconButton href="https://www.instagram.com/herbi_med/" target="_blank" className="social-icon">
                                <Instagram style={{ color: 'black' }}/>
                            </IconButton>
                        </Box>
                    </Box>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Box className="subscription">
                        <Typography variant="h6" className="subscription-title">
                            Subscribe to our newsletter and be the first to know about exciting offers
                        </Typography>
                        <Formik
                            initialValues={{ email: '' }}
                            onSubmit={(values) => {
                                console.log(values);
                            }}
                        >
                            {({ handleChange, values }) => (
                                <Form className="subscription-form">
                                    <Field
                                        as={TextField}
                                        name="email"
                                        type="email"
                                        placeholder="Enter your email"
                                        variant="outlined"
                                        fullWidth
                                        className="email-input"
                                        onChange={handleChange}
                                        value={values.email}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <IconButton type="submit" color="primary">
                                                        <Send />
                                                    </IconButton>
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                </Form>
                            )}
                        </Formik>
                    </Box>
                </Grid>
                <Grid item xs={12} sm={3}>
                    <Box className="company-links">

                        <Typography variant="h55" className="company-title">Company</Typography>
                        <Link to="/about" style={{textDecoration:"none", color:"black"}}>
                            <Typography variant="body2" className="company-link">About Us</Typography>
                        </Link>
                        <Link to="/partners" style={{textDecoration:"none", color:"black"}}>
                            <Typography variant="body2" className="company-link">Our Partners</Typography>
                        </Link>
                        <Link to="/practitioners"  style={{textDecoration:"none", color:"black"}}>
                            <Typography variant="body2" className="company-link">Our Practitioners</Typography>
                        </Link>
                        <Link to="/ContactUs" style={{textDecoration:"none", color:"black"}}>
                            <Typography variant="body2" className="company-link">Contact Us</Typography>
                        </Link>
                    </Box>
                </Grid>
            </Grid>
            <Box className="disclaimer">
                <Typography variant="body2">
                    These statements have not been evaluated by the Food and Drug Administration. These products are not intended to diagnose, treat, cure, or prevent disease.
                </Typography>
                
            </Box>
            <Typography variant="body1" sx={{marginTop:"10px"}}>
                    <a href="https://www.arccodes.com" target="_blank" style={{textDecoration:"none", color:"black"}}>Developed by Arc Codes</a>
                </Typography>
        </Box>
    );
};

export default Footer;
