import React, { useState, useEffect } from 'react';
import { Card, CardContent, CardMedia, Typography, CircularProgress, Box } from '@mui/material';
import { Link } from 'react-router-dom';
import axios from '../axiosConfig';
import PriceDisplay from './PriceDisplay';
import { createSlug } from '../utils/slugify';
import './ProductCard.css'; // Import the CSS file

const ProductCard = ({ product }) => {
  const [isOutOfStock, setIsOutOfStock] = useState(false);
  const [loading, setLoading] = useState(false);
  const [discount, setDiscount] = useState(0);

  let image = "";
  if (product.images[0].url === undefined) {
    image = product.images[0];
  } else {
    image = product.images[0].url;
  }
  // Insert the `f_auto` parameter into the URL
  // const urlParts = image.split('/upload/');
  // image = `${urlParts[0]}/upload/f_auto/${urlParts[1]}`

  const urlParts = image.split('/upload/');
image = `${urlParts[0]}/upload/f_webp,q_auto,dpr_auto,c_scale/${urlParts[1]}`;


  useEffect(() => {
    const checkAvailability = async () => {
      setLoading(true);
      try {
        const response = await axios.post('/products/check-availability', { id: product._id });
        setIsOutOfStock(response.data.isOutOfStock);
      } catch (error) {
        console.error('Error checking product availability:', error.response?.data || error.message);
      } finally {
        setLoading(false);
      }
    };

    const fetchDiscount = async () => {
      try {
        const response = await axios.get(`/sales/discount/${product._id}`);
        setDiscount(response.data.maxDiscount);
      } catch (error) {
        console.error('Error fetching discount:', error);
      }
    };

    if (product.quantity === undefined) {
      checkAvailability();
    } else {
      setIsOutOfStock(product.quantity === 0);
    }

    fetchDiscount();
  }, [product._id, product.quantity]);

  // if (loading) {
  //   return <CircularProgress />;
  // }

  return (
    <Link to={`/product/name/${createSlug(product.name)}`} style={{ textDecoration: 'none', color: 'inherit' }}>
      <Card className="product-card">
        <Box style={{ position: 'relative' }}>
          <CardMedia
            component="img"
            alt={product.name}
            height="240"
            image={image}
            loading="lazy"
            className="product-card-media"
          />
          {discount > 0 && (
            <Box className="discount-sticker">
              <Typography variant="h6" style={{ color: 'white', fontWeight: 'bold' }}>
                {discount}% OFF
              </Typography>
            </Box>
          )}
          {isOutOfStock && (
            <Box className="out-of-stock-overlay">
              <Typography variant="h4" style={{ color: 'white', fontWeight: 'bold' }}>
                Out of Stock
              </Typography>
            </Box>
          )}
        </Box>
        <CardContent className="product-card-content">
          <Typography className="product-card-title">{product.name}</Typography>
          <Typography className="product-card-subtitle">{product.packSize}</Typography>
          <PriceDisplay productId={product._id} price={product.price} />
        </CardContent>
      </Card>
    </Link>
  );
};

export default ProductCard;
