import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import { ThemeProvider, CssBaseline } from '@mui/material';
import ReactGA from 'react-ga';
import HomePage from './pages/HomePage';
import Categories from './pages/Categories';
import Header from './components/Header';
import Footer from './components/Footer';
import ProductPage from './pages/ProductPage';
import AdminHome from './pages/AdminHome';
import ContactUs from './pages/ContactUs';
import UserLogin from './pages/UserLogin';
import Cart from './pages/Cart';
import Checkout from './pages/Checkout';
import AdminLogin from './pages/AdminLogin';
import PinVerification from './pages/PinVerification';
import UserHome from './pages/UserHome';
import theme from './theme';
import AboutUs from './pages/AboutUs';
import OurPractitioners from './pages/OurPractitioners';
import Partners from './pages/Partners';
import Sales from './pages/Sales';
import AllProducts from './pages/AllProducts';
import SearchPage from './pages/SearchPage';

const trackingId = "G-3V85LKRY9B"; // Replace with your Google Analytics tracking ID
ReactGA.initialize(trackingId);
ReactGA.plugin.require('ec');

const App = () => {
  const location = useLocation();

  useEffect(() => {
    ReactGA.pageview(location.pathname + location.search);
  }, [location]);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Routes>
        <Route path="/" element={<><Header /><HomePage /><Footer /></>} />
        {/* <Route path="/product/:id" element={<><Header /><ProductPage /><Footer /></>} /> */}
        <Route path="/product/name/:name" element={<><Header /><ProductPage /><Footer /></>} />
        <Route path="/search" element={<><Header /><SearchPage /><Footer /></>} />
        <Route path="/allproducts" element={<><Header /><AllProducts /><Footer /></>} />
        <Route path="/sales" element={<><Header /><Sales /><Footer /></>} />
        <Route path="/admin/*" element={<AdminHome />} />
        <Route path="/ContactUs" element={<><Header /><ContactUs /><Footer /></>} />
        <Route path="/Cart" element={<><Header /><Cart /><Footer /></>} />
        <Route path="/Checkout" element={<><Header /><Checkout /><Footer /></>} />
        <Route path="/about" element={<><Header /><AboutUs /><Footer /></>} />
        <Route path="/practitioners" element={<><Header /><OurPractitioners /><Footer /></>} />
        <Route path="/partners" element={<><Header /><Partners /><Footer /></>} />
        <Route path="/login" element={<UserLogin />} />
        <Route path="/:type/:identifier" element={<><Header /><Categories /><Footer /></>} />
        <Route path="/admin/login" element={<AdminLogin />} />
        <Route path="/verify-pin" element={<PinVerification />} />
        <Route path="/userhome/*" element={<UserHome />} />
      </Routes>
    </ThemeProvider>
  );
};

const AppWithRouter = () => (
  <Router>
    <App />
  </Router>
);

export default AppWithRouter;
