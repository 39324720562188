import React from 'react';
import './ProductDisplay.css';
import { Box, Typography, Chip, Button } from '@mui/material';
import PriceDisplay from './PriceDisplay';
import { useNavigate } from 'react-router-dom';
import { createSlug } from '../utils/slugify';

const ProductDisplay = ({ product, mainImage, handleThumbnailClick, selectedThumbnail, handleAddToCart }) => {
  const navigate = useNavigate();

  const handleTagClick = (tag) => {
    navigate(`/tags/${createSlug(tag)}`);
  };

  return (
    <Box className="product-display">
      <Box className="product-display-left">
        <Box className="product-display-thumbnails">
          {product.images.map((src, index) => (
            <img
              src={`${src.url.split('/upload/')[0]}/upload/f_webp,q_auto,dpr_auto,c_scale/${src.url.split('/upload/')[1]}`}
              alt={src.alt}
              className={`thumbnail ${selectedThumbnail === index ? 'selected' : ''}`}
              key={index}
              loading="lazy"
              onClick={() => handleThumbnailClick(src.url, index)}
            />
          ))}
        </Box>
        <Box className="product-display-main-image">
          <img className="main-image" src={`${mainImage.split('/upload/')[0]}/upload/f_auto/${mainImage.split('/upload/')[1]}`} alt="Main Product" />
        </Box>
      </Box>
      <Box className="product-display-right">
        <Typography variant="h4" className="product-title">{product.name}</Typography>
        <Typography variant="h5" className="product-packsize">{product.packSize}</Typography>
        <Box className="product-tags">
          {product.tags.map((tag, index) => (
            <Chip
              label={tag}
              key={index}
              onClick={() => handleTagClick(tag)}
              clickable
              className="product-tag"
            />
          ))}
        </Box>
        <PriceDisplay productId={product._id} price={product.price} discountPercentage={product.discountPercentage} />
        <ul className="product-features">
          {product.features.map((feature, index) => (
            <li key={index}>{feature}</li>
          ))}
        </ul>
        {product.quantity > 0 ? (
          <Button variant="contained" color="primary" onClick={handleAddToCart} className="add-to-cart-btn">Add to Cart</Button>
        ) : (
          <Button variant="contained" disabled className="out-of-stock-btn">Out of Stock</Button>
        )}
      </Box>
    </Box>
  );
};

export default ProductDisplay;
