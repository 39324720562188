const slugify = require('slugify');

// Convert a string to a URL-friendly slug
const createSlug = (text) => {
  return slugify(
    text
      .replace(/-/g, '=')          // Replace pre-existing dashes with '='
      .replace(/'s\b/g, '=s=')     // Handle possessive 's
      .replace(/\+/g, '=plus='),   // Replace '+' with a placeholder
    {
      lower: true,
      remove: /[*~.()'"!:@]/g,     // Remove unwanted characters
    }
  );
};

// Convert a slug back to the original string format
const deslugify = (slug) => {
  return slug
    .replace(/=s=/g, "'s")         // Restore possessive 's
    .replace(/=plus=/g, '+')       // Restore '+'
    .replace(/-/g, ' ')            // Convert slug-introduced dashes to spaces
    .replace(/\b\w/g, (char) => char.toUpperCase()) // Capitalize first letter of each word
    .replace(/=/g, '-')            // Restore pre-existing dashes
    .trim();
};

module.exports = { createSlug, deslugify };
